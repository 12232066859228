.inScreen {
  /* opacity: 0.5; */
  opacity: 1;
  /* set to 1 to deactivate */
}

@keyframes opacity1 {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideInFromBottom2 {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 100%;
  }
}

.slideUp {
  animation: 0.5s ease-in-out opacity1;
  opacity: 1;
}
/**end**/
