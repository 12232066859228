/* .collapsable {
    padding: 0;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s ease-out;
    background-color: #f1f1f1;
} */

span.small {
  font-size: 90%;
  margin-bottom: 1rem;
}

.uploadDiv > * {
  margin: 0;
  padding: 1rem;
}

.uploadDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 2rem;
}

.uploadDiv > button {
  background: #c33d32;
  border: 2px solid #c33d32;
  color: white;
}

.uploadDiv > button:hover {
  background: white;
  color: #c33d32;
}

.uploadDiv > input {
  width: 100%;
}
