.mainHome {
  display: flex;
  min-height: calc(100vh - 213px);
  padding: 4rem;
  justify-content: space-evenly;
}

.mainHome > div {
  flex: 1;
  padding: 2rem;
}

.mainHome > div h2 {
  text-align: center;
  font-size: 1.55rem;
  margin-bottom: 2rem;
}
.mainHome > div h2:after {
  content: "";
  width: 2.5rem;
  height: 4px;
  background-color: #c33d32;
  display: block;
  margin: 1rem auto 2rem;
}

.mainHome > div h3 {
  text-align: center;
  font-size: 1.5rem;
  line-height: 1.25;
  margin-bottom: 3rem;
  color: #c33d32;
}

.mainHome img {
  max-width: 100%;
}

.exit_site {
  display: block;
}

/** exit popup **/
.popup_wrapper {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.inner-pop {
  background: #fff;
  border-radius: 10px;
  padding: 50px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-flow: column wrap;
  -webkit-flex-flow: column wrap;
  -moz-flow: column wrap;
  -ms-flow: column wrap;
  justify-content: center;
  align-items: center;
  margin-left: 30px;
  margin-right: 30px;
  max-width: 50%;
  text-align: center;
}
.inner-pop > h1 {
  margin-bottom: 25px;
}
.inner-pop a {
  background: #c33d32;
  padding: 10px 20px;
  color: #ffffff;
  margin-right: 0;
  border: 2px solid #c33d32;
  padding: 15px 30px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  cursor: pointer;
}
.inner-pop p {
  margin: 0.5rem 0;
}
.inner-pop a:last-child {
  background: white;
  color: #c33d32;
}
.inner-pop .control {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  margin-top: 2rem;
}
.inner-pop .control a:first-child {
  margin-right: 12px;
}
.inner-pop .control a {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: "Montserrat", sans-serif;
}
