* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
}

/** css loader**/
.loaderWindow {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-transition: 0.25s all ease;
  transition: 0.25s all ease;
}
.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #c33d32;
  width: 80px;
  height: 80px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

/* safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/**endLoader**/

.App {
  width: 100%;
  height: 100%;
  background-color: white;
}

.AppMain,
.InfoHeader {
  width: 100%;
  /* max-width: 100%;
  margin-left: auto;
  margin-right: auto; */
  display: flex;
}

.InfoHeader {
  background-color: #c33d32;
  z-index: 9;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  font-family: "Montserrat";
  font-size: 12px;
  padding: 0.75rem 0;
  color: #fff;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 700;
  align-items: center;
}
.InfoHeader a {
  margin-left: 1.5rem;
  text-decoration: none;
}

.AppMain > header {
  flex: 1;
  width: 100%;
  max-width: 375px;
  position: fixed;
}

.InfoHeader a {
  margin-left: 1.5rem;
  text-decoration: none;
}

.InfoHeader a button {
  background: #c33d32;
  border: 3px solid #fff;
  transition: all 0.25s ease;
  font-weight: 700;
  padding: 0.5rem 1.75rem;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.InfoHeader a button:focus,
.InfoHeader a button:hover {
  background: #fff;
  border: 3px solid #fff;
  color: #c33d32;
}

.AppMain > header:after {
  content: "";
  display: block;
  height: 100%;
  max-width: 68px;
  background-image: url(./src/images/navg.png);
}

section.root {
  /* width: 100%; */
  width: calc(100% - 375px);
  flex: 1;
  max-width: 100%;
  margin-left: 375px;
}

footer {
  position: fixed;
  flex: 0;
  bottom: 15px;
  left: 0;
  width: 300px;
  text-align: center;
  padding: 10px;
  color: black;
  width: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column wrap;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 2px;
}

button {
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  border: none;
  outline: none;
  width: 100%;
  padding: 15px 0;
  color: #fff;
  font-size: 16px;
  letter-spacing: 1px;
  background: #c33d32;
  cursor: pointer;
}

.login {
  width: 100%;
  min-height: 100vh;
  padding: 0 20px;
  background: #e9e9e9;
  display: flex;
  justify-content: center;
}

.login .loginContainer {
  position: relative;
  font-family: "Montserrat", sans-serif;
  padding: 50px;
  margin: 130px 50px auto auto;
  width: 100%;
  max-width: 400px;
  min-height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: radial-gradient(
    ellipse at left bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(245, 245, 245, 0.9) 59%,
    rgba(248, 240, 240, 0.9) 100%
  );
  box-shadow: 0 50px 70px -20px rgba(0, 0, 0, 0.8);
  border-bottom: 10px solid #c33d32;
}

.login .memberLogin {
  position: absolute;
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  border: none;
  outline: none;
  width: 100%;
  padding: 15px 0;
  color: #fff;
  font-size: 16px;
  letter-spacing: 1px;
  background: #c33d32;
  cursor: pointer;
  max-width: 240px;
  text-align: center;
  top: -80px;
  right: 0;
}

.login .memberLogin:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 30px solid #c33d32;
  position: absolute;
  right: 10px;
}

.login .loginContainer label {
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: black;
  margin: 14px 0;
  display: block;
  font-size: 20px;
  line-height: 1;
}

.login .loginContainer input {
  width: 100%;
  border: none;
  outline: none;
  font-size: 19px;
  padding: 10px;
  background: white;
  color: black;
  letter-spacing: 1px;
  border: 2px solid black;
}

.login .loginContainer .btnContainer {
  width: 100%;
  padding: 0;
}

.login .loginContainer .btnContainer p {
  font-weight: 900;
  margin: 14px 0 0 0;
  text-align: left;
  color: black;
}

.login .loginContainer .btnContainer p span {
  color: black;
  font-weight: 500;
  letter-spacing: 0.5px;
  margin-left: 5px;
  cursor: pointer;
  margin-left: 10px;
  transition: all 400ms ease-in-out;
}

.login .loginContainer .btnContainer p span:hover {
  color: #c33d32;
}

.login .loginContainer .errorMsg {
  color: #c33d32;
  font-size: 16px;
}

.introContainer {
  position: absolute;
  -webkit-clip-path: polygon(
    97.5% 0,
    100% 50%,
    97.5% 100%,
    2.5% 100%,
    0 50%,
    2.5% 0
  );
  clip-path: polygon(97.5% 0, 100% 50%, 97.5% 100%, 2.5% 100%, 0 50%, 2.5% 0);
  bottom: 6.5%;
  background-color: #c33d32;
  padding: 20px 40px;
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  border: none;
  outline: none;
  width: auto;
  padding: 24px 39px;
  color: #fff;
  font-size: 28px;
  letter-spacing: 2px;
  background: #c33d32;
  text-align: center;
}

.introContainer span {
  font-size: small;
  vertical-align: super;
  position: relative;
}

.introContainer span:after {
  content: "";
  display: inline-block;
  height: 2.5px;
  width: 100%;
  background: black;
  position: absolute;
  left: 0;
  bottom: -6px;
}

/**player**/
.ytp-pause-overlay-controls-hidden .ytp-pause-overlay,
.ytp-pause-overlay-controls-hidden .ytp-pause-overlay {
  display: none !important;
}
