
.slide_root {
    width: 100%;
    min-height: 100vh;
    position: fixed;
}

.slick-slide img {
    display: block;
    width: 100%;
    max-width: calc(100% - 375px);
    margin: 0;
    height: 100vh;
    object-fit: cover;
}