.navStart {
  position: fixed;
  left: 0;
  top: 0;
  background-color: #fff;
  z-index: 1;
}

.navStart:after {
  content: "";
  display: block;
  height: 100%;
  max-width: 68px;
  background-image: url(../src/images/navg.png);
  position: absolute;
  top: 0;
  left: 355px;
  width: 100%;
  height: 100vh;
}

.g1 {
  background-image: url(../src/dec/g2s.png);
  position: absolute;
  top: 0;
  width: 360px;
  height: 100vh;
  background-size: 200%;
  left: 0;
  background-position: right;
}

.g2 {
  background-image: url(../src/dec/g1s.png);
  position: absolute;
  width: 405px;
  background-size: 260%;
  left: 0px;
  top: 0;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: initial;
}

.g3 {
  background-image: url(../src/dec/g3s.png);
  position: absolute;
  width: 405px;
  height: 88vh;
  background-size: 200%;
  left: 0;
  background-position: -370px 100px;
  background-repeat: no-repeat;
  top: 610px;
}

/**navItems**/
.NavItems {
  width: 350px;
  height: 100vh;
  min-height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  padding-top: 20px;
  padding-bottom: 150px;
}
.NavItems ul {
  z-index: 1;
}
img {
  width: 100%;
  max-width: 240px;
  margin: 30px auto;
  display: flex;
  justify-self: normal;
}

.logo-text {
  margin: 0 0 60px;
  color: #c33d32;
  letter-spacing: 1.75px;
}

ul {
  list-style: none;
  min-width: 220px;
  transition: all 0.5s ease;
  position: relative;
}

ul li {
  padding: 15px 0 0;
  font-size: 15px;
  letter-spacing: 1.5px;
  cursor: pointer;
  position: relative;
  -webkit-transition: all 0.5s ease;
  transition: all 0 ease;
}

ul li a {
  color: black;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

/* ul li.admin {
    display: none;
} */

ul li.admin a,
ul li.all a {
  color: #c33d32;
  text-decoration: none;
  cursor: pointer;
}

ul > li a:after {
  content: "";
  display: block;
  height: 3px;
  background: #c33d32;
  width: 60px;
  margin: 15px 0 0;
  -webkit-transition: width 0.5s;
  transition: width 0.5s;
  flex-basis: 100%;
}

ul > li:not(:first-child) a {
  position: relative;
}

/* ul > li:not(:first-child) a:before, */
ul > li:not(.noDD) a:before {
  content: "^";
  position: absolute;
  left: 100%;
  transform: rotate(180deg);
}

ul > li a:hover,
ul > li a:focus,
ul > li a:active {
  color: #c33d32;
}

ul > li a:hover:after,
ul > li a:hover:after {
  width: 55%;
}

ul > li:after:hover {
  width: 100%;
}

ul > li > ul {
  max-height: 0;
  transition: max-height 0.25s ease-out;
  overflow: hidden;
  padding-left: 15px;
}

ul > li > ul:after {
  content: "";
  display: block;
  height: 3px;
  background: #c33d32;
  width: 60px;
  margin: 15px 0 0;
  -webkit-transition: width 0.5s;
  transition: width 0.5s;
  transform: translateX(-15px);

  content: none;
}

ul > li > ul > li a {
  color: #c33d32;
}

ul > li > ul > li a:hover {
  color: #000;
}

ul > li > ul > li a:after {
  content: none;
}

/*
ul > li:hover > ul {
*/
ul > li:focus > ul,
ul > li:focus-within > ul {
  max-height: 500px;
  transition: max-height 0.25s ease-in;
}

ul ul {
  margin-left: 20px;
  max-height: 0px;
  transition: max-height 0.4s ease;
  overflow: hidden;
}

li.active > a > span {
  transform: rotate(180deg);
}

li:hover span {
  text-decoration: underline;
  text-decoration-color: transparent;
}

a.blocked {
  pointer-events: none;
}

ul > li:focus:not(:first-child) a:before,
ul > li:focus-within:not(:first-child) a:before {
  transform: rotate(0deg);
}

/**thirdLevel**/
li.subLi ul:after {
  content: none;
}
li.subLi li {
  position: relative;
}
li.subLi li:before {
  content: "-";
  position: absolute;
  left: -15px;
}
