.verifyUploadDiv {
  display: flex;
  flex-direction: column;
}

.catSpan {
  display: inline-block;
  background-color: #c33d32;
  padding: 0.5rem 1rem;
  color: white !important;
}

.mainSection {
  flex: 1 0 80%;
  width: 100%;
  min-height: 100vh;
  background: radial-gradient(
    ellipse at left bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(245, 245, 245, 0.9) 59%,
    rgba(220, 220, 220, 0.9) 100%
  );
}

.mainSection nav {
  background: black;
  width: 100%;
  padding: 20px 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-image: url(../src/images/headerimg1.png);
  background-size: cover;
  background-position: center;
  min-height: 213px;
}

.mainSection nav h2 {
  color: #fff;
}

.mainSection nav button {
  max-width: 120px;
}

main {
  width: 100%;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 35px;
  padding-left: 30px;
}

main h1,
main h2 {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  font-family: "Montserrat", sans-serif;
  font-size: 32px;
  text-transform: uppercase;
  letter-spacing: 5px;
}

main h1 {
  color: #c33d32;
  font-weight: 900;
  padding: 40px 80px;
}
main h2 {
  color: black;
  font-weight: 700;
  padding: 0;
}

main section.promosSec .box {
  flex: 1 1 50%;
  display: flex;
  min-height: 442px;
  justify-content: center;
  align-items: center;
  padding: 50px 80px;
  max-width: 1400px;
  margin: 0 auto;
}
main section.promosSec.tight .box {
  min-height: 250px;
  padding: 20px 80px;
}

main section.promosSec li:nth-child(odd),
main.admin section.inputbox {
  background-image: url(../src/images/item_bg.jpg);
  background-size: cover;
}
main.admin section.inputbox2 {
  background-image: url(../src/images/item_bg.jpg);
  background-size: cover;
}

/* main section.promosSec.print:nth-child(even) li {
    background-image: url(../src/images/item_bg.jpg);
    background-size: cover;
}
main section.promosSec.print:nth-child(odd) li {
    background-image: none;
} */

main section button {
  background: none;
  border: 2px solid #c33d32;
  font-size: 12px;
  color: #c33d32;
  padding: 10px;
  margin-top: 25px;
  max-width: 190px;
}

main section button:hover,
main section button:focus {
  background: #c33d32;
  border-color: #c33d32;
  color: white;
}

main section.promosSec .box .info {
  flex: 1 1 50%;
  padding: 50px;
}

main section.promosSec .box .info span,
main section.promosSec .box h3 {
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 900;
  color: #c33d32;
}

main section.promosSec .info h2 {
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  font-size: 33px;
  letter-spacing: 6px;
  font-weight: 900;
  color: black;
  padding: 10px 0;
}

main section.promosSec li > h2 {
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  font-size: 33px;
  letter-spacing: 6px;
  font-weight: 900;
  color: black;
  padding: 30px 80px 0;
}

main section.promosSec .box .info h2:after {
  content: "";
  display: block;
  width: 33px;
  height: 3px;
  background-color: #c33d32;
  margin: 15px 0;
}

main section.promosSec .box img {
  /* max-width: 100%; ap 033021 */
  max-width: 500px;
  margin: 0;
}

main section .box .info p {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 500;
  margin-top: 25px;
}

/**social**/
main.grundge1 {
  background-image: url(../src/images/grundge_bg.jpg);
  background-size: 100% 100vh;
}

section.socialSec ul {
  display: flex;
  padding: 0 50px 50px;
  max-width: 1400px;
  margin: 0 auto;
  flex-flow: row wrap;
}

section.socialSec img {
  max-width: 100%;
  margin: 0;
}
section.storiesSec img {
  max-width: 100%;
  min-height: 455px;
}

section.socialSec ul li {
  flex: 0 0 33%;
  padding: 30px;
}

section.socialSec h2 {
  padding: 0 80px;
}

section.socialSec ul li button {
  max-width: 100%;
}

/** print **/
main section.promosSec.print .box {
  padding: 0 80px;
  min-height: 400px;
}

main section.promosSec.print img {
  min-width: 300px;
  max-width: 300px;
}

/** photography - in loop css**/

/** video **/
section.socialSec ul.videoUL li {
  flex: 0 0 50%;
}
section.socialSec ul.videoUL li img {
  min-height: 360px;
  object-fit: cover;
}

/*********/
/**admin**/
/*********/
input,
textarea,
select {
  padding: 15px;
  border: none;
  -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  background-color: white;
}

main.admin {
  padding: 0;
}

main.admin h3 {
  margin-bottom: 10px;
  font-family: "Roboto";
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 16px;
  color: #444;
}

main.admin .add {
  padding: 80px;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
}

main.admin section.inputbox {
  padding: 80px;
  margin: 0 20%;
  max-width: 100%;
  margin: 0 auto;
}

main.admin section.inputbox2 {
  padding: 40px 80px;
  margin: 0 20%;
  max-width: 100%;
  margin: 0 auto;
}

main.admin .add h1 {
  padding-top: 20px;
}

main.admin section.inputbox form {
  display: flex;
  flex-flow: column wrap;
}

main.admin .nameIntro {
  background: #c33d32;
  padding: 14px 80px;
  color: #fff;
  font-size: 11px;
  letter-spacing: 2px;
}

h2.signageTitle {
  padding: 0 80px 20px;
  font-size: 20px;
  font-weight: 900;
}

/**deleters**/
button.deleter_full {
  background: #c33d32;
  font-family: "Montserrat", sans-serif;
  border: 2px solid #c33d32;
  font-size: 12px;
  color: #ffffff;
  padding: 10px;
  margin-top: 25px;
  max-width: 40px;
  position: absolute;
  right: 0;
}

button.deleter:hover:after,
button.deleter:focus:after {
  content: "kill";
  display: flex;
  height: auto;
  width: auto;
  position: absolute;
  top: -30px;
  right: 4px;
  color: #c33d32;
  font-size: 12.5px;
}

button.deleter_3up {
  width: 40px;
  position: absolute;
  right: 40px;
  top: 14px;
  background: #c33d32;
  color: white;
}
