section.threeCol .wrapSlider {
    background-size: cover;
    min-height: 350px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    padding: 0 80px;
}

section.threeCol:nth-child(even) .wrapSlider {
    background-image: url(../src/images/item_bg.jpg);
    background-size: cover;
}

section.threeCol  .wrapSlider > h2 {
    padding-top: 40px;
}
section.threeCol  .slick-list {
    padding: 0 80px 40px;
}

section.threeCol .box {
    padding: 0 35px 0 0;
    transform: translateX(-80px);
}

section.threeCol .image_preview {
    width: 100%;
}

section.threeCol .slick-slide img {
    max-width: 100%;
    min-width: 200px;
    height: auto;
    width: 100%;
}

section.threeCol button {
    width: 100%;
    max-width: 100%;
}